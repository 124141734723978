<template>
	<v-autocomplete v-bind="$attrs" v-on="$listeners">
		<!-- Pass on all named slots -->
		<slot v-for="slot in Object.keys($slots)" :v-slot="slot" :name="slot"></slot>
		<!-- Pass on all scoped slots -->
		<template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"></slot></template>
	</v-autocomplete>
</template>

<script>
/**
 * @displayName w-autocomplete
 *
 * @since 0.3.2
 */
export default {
	name: 'WAutocomplete'
}
</script>
